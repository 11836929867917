import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { config } from "../config";
import { useMeQuery } from "../queries/me.queries";
import CompanyNavBar from "../company-navbar";

export function CompanyProtectedRoute() {
  const navigate = useNavigate();
  const { data: currentUser, isFetched } = useMeQuery();

  useEffect(() => {
    if (
      config.FF_ENABLE_ACCEPT_PRIVACY &&
      isFetched &&
      !currentUser?.acceptedPrivacy
    ) {
      navigate("/accept-privacy");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, currentUser]);

  if (!isFetched) return null;

  return (
    <div>
      <CompanyNavBar />
      <Outlet />
    </div>
  );
}
