import React from "react";
import { Outlet, Navigate, createBrowserRouter } from "react-router-dom";

import RedirectToCompany from "../redirect-to-company";
import { RootRouteError } from "../common/root-route-error";
import { MANAGE_LICENSE_ROUTES } from "../licenses/routes";
import { SYSTEM_ADMIN_ROUTES } from "../system-admins/routes";
import { MANAGE_ROLE_ROUTES } from "../roles/role-routes";
import { MANAGE_AUDIT_LOG_ROUTES } from "../audit-log/routes";
import { PERMISSION_ROUTES } from "../permissions/routes";
import { APPLICATION_ROUTES } from "../applications/routes";
import { DOMAIN_ROUTES } from "../domains/routes";
import { MANAGE_COMPANY_ROUTES } from "../companies/routes";
import { COMPANY_USER_ROUTES } from "../users/routes";
import { COMPANY_USER_GROUPS_ROUTES } from "../groups/group-routes";
import { COMPANY_API_KEY_ROUTES } from "../api-keys/routes";
import { COMPANY_ROLE_ROUTES } from "../company-roles/company-role-routes";
import { ACCOUNT_ROUTES } from "../account/routes";
import { COMPANY_CUSTOMER } from "../customers/customer-routes";
import { RootRoute } from "./root";
import { ProtectedRoute } from "./protected-route";
import { AccountsProtectedRoute } from "./accounts-protected-routes";
import { CompanyProtectedRoute } from "./company-protected-route";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <RedirectToCompany />,
  },
  {
    path: "auth/callback",
    lazy: () => import("../auth/auth-callback"),
  },
  {
    path: "/",
    element: <RootRoute />,
    errorElement: <RootRouteError />,
    children: [
      {
        path: "auth/unauthorized",
        lazy: () => import("../permission-denied-page/permission-denied-page"),
      },
      {
        path: ":companyId/invite-accepted",
        lazy: () => import("../common/invite-redirect"),
      },
      {
        path: "invite-revoke",
        lazy: () => import("../common/invite-redirect"),
      },
      {
        path: "logout",
        lazy: () => import("../logout"),
      },
      {
        path: "login",
        lazy: () => import("../login"),
      },
      {
        path: "accept",
        lazy: () => import("../common/accept-invite"),
      },
      {
        path: "accept-privacy",
        lazy: () => import("../common/accept-privacy"),
      },
      {
        path: "/me/*",
        element: <AccountsProtectedRoute />,
        children: ACCOUNT_ROUTES,
      },
      {
        path: "/manage/*",
        element: <ProtectedRoute />,

        children: [
          {
            index: true,
            element: <Navigate to="companies" replace />,
          },
          {
            path: "companies/*",
            element: <Outlet />,
            children: MANAGE_COMPANY_ROUTES,
          },

          {
            path: "licenses/*",
            element: <Outlet />,
            children: MANAGE_LICENSE_ROUTES,
          },

          {
            path: "roles/*",
            element: <Outlet />,
            children: MANAGE_ROLE_ROUTES,
          },
          {
            path: "permissions/*",
            element: <Outlet />,
            children: PERMISSION_ROUTES,
          },

          {
            path: "applications/*",
            element: <Outlet />,
            children: APPLICATION_ROUTES,
          },
          {
            path: "domains/*",
            element: <Outlet />,
            children: DOMAIN_ROUTES,
          },
          {
            path: "system-admins/*",
            element: <Outlet />,
            children: SYSTEM_ADMIN_ROUTES,
          },
          {
            path: "audit-log/*",
            element: <Outlet />,
            children: MANAGE_AUDIT_LOG_ROUTES,
          },
        ],
      },
      {
        path: "/:companyId/*",
        element: <CompanyProtectedRoute />,
        children: [
          {
            path: "users/*",
            element: <Outlet />,
            children: COMPANY_USER_ROUTES,
          },
          {
            path: "groups/*",
            element: <Outlet />,
            children: COMPANY_USER_GROUPS_ROUTES,
          },
          {
            path: "api-keys/*",
            element: <Outlet />,
            children: COMPANY_API_KEY_ROUTES,
          },
          {
            path: "licenses",
            element: <Outlet />,
            children: [
              {
                index: true,
                lazy: () => import("../company-license/license-list"),
              },
            ],
          },
          {
            path: "customers/*",
            element: <Outlet />,
            children: COMPANY_CUSTOMER,
          },
          {
            path: "roles/*",
            element: <Outlet />,
            children: COMPANY_ROLE_ROUTES,
          },
        ],
      },
      {
        path: "*",
        element: <RedirectToCompany />,
      },
    ],
  },
]);
