import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { config } from "./config";
import { hasPermission } from "./helper";
import { Permission } from "./constant";
import { useInternalCompaniesQuery } from "./queries/internal-companies.queries";
import { useMeQuery } from "./queries/me.queries";

export default function RedirectToCompany() {
  const navigate = useNavigate();
  const { data: currentUser, isFetched: isCurrentUserFetched } = useMeQuery();
  const { data: companies = [], isFetched: isCompaniesFetched } =
    useInternalCompaniesQuery();

  useEffect(() => {
    if (!isCurrentUserFetched || !isCompaniesFetched) return;
    if (config.FF_ENABLE_ACCEPT_PRIVACY && !currentUser?.acceptedPrivacy) {
      navigate("/accept-privacy");
      return;
    }

    const isSystemAdmin = hasPermission(
      currentUser?.permissions,
      Permission.SYSTEM_ADMIN,
    );

    if (companies.length > 0) {
      const companyId = companies[0].id;
      sessionStorage.setItem("company-id", companyId.toString());
      navigate(`/${companyId}/users`);
    } else if (isSystemAdmin) {
      navigate(`/manage/companies`);
    } else {
      navigate("/auth/unauthorized");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentUserFetched, isCompaniesFetched]);

  return null;
}
