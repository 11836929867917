import React from "react";
import { useLocation } from "react-router-dom";
import { t } from "@lingui/macro";
import { TopNavigation, TopNavigationItems } from "@fidelix/fx-miranda";
import ProfileMenu from "../profile-menu/profile-menu";

export default function ManageNavbar() {
  const { pathname } = useLocation();

  const isRouteActive = (routeTo: string): boolean => {
    const parts = pathname.split("/");
    const routeParts = routeTo.split("/");
    return parts[2] === routeTo || parts[2] === routeParts[2];
  };
  const routes: TopNavigationItems = [
    {
      label: t`Companies`,
      to: "/manage/companies",
      isActive: isRouteActive("companies"),
    },
    {
      label: t`Licenses`,
      to: "/manage/licenses",
      isActive: isRouteActive("licenses"),
    },
    { label: t`Roles`, to: "/manage/roles", isActive: isRouteActive("roles") },
    {
      label: t`Permissions`,
      to: "/manage/permissions",
      isActive: isRouteActive("permissions"),
    },
    {
      label: t`Applications`,
      to: "/manage/applications",
      isActive: isRouteActive("applications"),
    },
    {
      label: t`Domains`,
      to: "/manage/domains",
      isActive: isRouteActive("domains"),
    },
    {
      label: t`System Admins`,
      to: "/manage/system-admins",
      isActive: isRouteActive("system-admins"),
    },
    {
      label: t`Audit Log`,
      to: "/manage/audit-log",
      isActive: isRouteActive("audit-log"),
    },
  ];

  return (
    <>
      <TopNavigation variant="secondary">
        <TopNavigation.Logo menuItems={[]} />
        <TopNavigation.Items items={routes} />
        <TopNavigation.Actions>
          <ProfileMenu name="AD" id="Admin" />
        </TopNavigation.Actions>
      </TopNavigation>
    </>
  );
}
