import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useMeQuery } from "../queries/me.queries";
import ManageNavbar from "../nav-bar/manage-nav-bar";
import { config } from "../config";

export function ProtectedRoute() {
  const navigate = useNavigate();
  const { data: currentUser, isFetched } = useMeQuery();

  useEffect(() => {
    if (
      config.FF_ENABLE_ACCEPT_PRIVACY &&
      isFetched &&
      !currentUser?.acceptedPrivacy
    ) {
      navigate("/accept-privacy");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, currentUser]);

  if (!isFetched) return null;

  return (
    <div>
      <ManageNavbar />
      <Outlet />
    </div>
  );
}
