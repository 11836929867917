import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { t } from "@lingui/macro";
import { Stack, TopNavigation, TopNavigationItems } from "@fidelix/fx-miranda";
import { useQueryClient } from "@tanstack/react-query";

import { Company } from "./dtos/company.model";
import CompanyManager from "./company-manager";
import ProfileMenu from "./profile-menu/profile-menu";
import { useMeQuery } from "./queries/me.queries";
import { useInternalCompaniesQuery } from "./queries/internal-companies.queries";
import { usePathNavigator } from "./auth/auth.utils";
import { Permission } from "./constant";
import { hasPermission } from "./helper";
import { apiRequest } from "./auth/api-request";

export default function CompanyNavBar() {
  const { pathname } = useLocation();
  const { companyId } = useParams();
  const { withSubUrl } = usePathNavigator();
  const { data: currentUser } = useMeQuery();
  const { data: companies = [] } = useInternalCompaniesQuery();
  const company = companies.find((company) => company.id === +companyId);
  const isCustomer = company && !new Company(company).isCompany();
  const queryClient = useQueryClient();

  const isRouteActive = (routeTo: string): boolean => {
    const parts = pathname.split("/");
    const routeParts = routeTo.split("/");
    return parts[2] === routeTo || parts[2] === routeParts[2];
  };

  useEffect(() => {
    (async () => {
      await apiRequest.refreshAccessToken();
      queryClient.invalidateQueries({ queryKey: ["get-me"] });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const permissions = currentUser?.permissions || [];
  const routes: TopNavigationItems = [
    {
      label: t`Users`,
      to: `/${companyId}/users`,
      isActive: isRouteActive("users"),
    },
    {
      label: t`User groups`,
      to: `/${companyId}/groups`,
      isActive: isRouteActive("groups"),
    },
    {
      label: t`Licenses`,
      to: `/${companyId}/licenses`,
      isActive: isRouteActive("licenses"),
    },
    ...(isCustomer
      ? []
      : [
          {
            label: t`Customers`,
            to: `/${companyId}/customers`,
            isActive: isRouteActive("customers"),
          },
        ]),
    {
      label: t`Roles`,
      to: `/${companyId}/roles`,
      isActive: isRouteActive("roles"),
    },
    {
      label: t`API keys`,
      to: `/${companyId}/api-keys`,
      isActive: isRouteActive("api-keys"),
    },
  ];

  const menuItems = [
    {
      to: withSubUrl(`https://analytics.fidelix.com/company/${companyId}`),
      label: t`SCADA`,
      permission: Permission.ANALYTICS,
    },
    {
      to: withSubUrl(`https://assets.fidelix.com/company/${companyId}`),
      label: t`Device Management`,
      permission: Permission.ASSETS,
    },
  ].filter((item) => hasPermission(permissions, item.permission));

  return (
    <>
      <TopNavigation variant="primary">
        <TopNavigation.Logo menuItems={menuItems} />
        <TopNavigation.Items items={routes} />
        <TopNavigation.Actions>
          <Stack axis="x" spacing="medium" align="center">
            <CompanyManager />
            <ProfileMenu name={currentUser?.fullName || ""} id="Member" />
          </Stack>
        </TopNavigation.Actions>
      </TopNavigation>
    </>
  );
}
