import { Company } from "./company.model";
import { Entity } from "./entity.model";

export class Invite extends Entity {
  userId: number;
  accepted: boolean;
  inviteKey: string;
  companyId: number;
  email: string;
  firstName: string;
  lastName: string;
  createdAt: Date;
  createdBy: string;
  roles: number[];
  expiredAt: Date;
  groupId: number;
  company: Company;
  active: boolean;
  language: string;

  constructor({
    id,
    userId,
    accepted,
    inviteKey,
    companyId,
    email,
    firstName,
    lastName,
    roles,
    createdBy,
    createdAt,
    expiredAt,
    groupId,
    company,
    active,
    language,
  }: Invite) {
    super(id);
    this.userId = userId;
    this.accepted = accepted;
    this.inviteKey = inviteKey;
    this.companyId = companyId;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.roles = roles;
    this.createdBy = createdBy;
    this.groupId = groupId;
    this.createdAt = new Date(createdAt);
    this.expiredAt = new Date(expiredAt);
    this.company = company && new Company(company);
    this.active = active;
    this.language = language;
  }
}
