export const config = {
  URL: import.meta.env.VITE_URL as string,
  API_URL: import.meta.env.VITE_API_URL as string,
  ASSETS_API_URL: import.meta.env.VITE_ASSETS_API_URL as string,
  ADMIN_URL: import.meta.env.VITE_ADMIN_URL as string,
  ALLOWED_DOMAINS: import.meta.env.VITE_ALLOWED_DOMAINS as string,
  LOGIN_URL: import.meta.env.VITE_LOGIN_URL as string,
  LOGOUT_URL: import.meta.env.VITE_LOGOUT_URL as string,
  FF_ENABLE_ACCEPT_PRIVACY: import.meta.env.VITE_FF_ENABLE_ACCEPT_PRIVACY,
} as const;

export type Config = typeof config;
