import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { TopNavigation } from "@fidelix/fx-miranda";

import { useMeQuery } from "../queries/me.queries";
import ProfileMenu from "../profile-menu/profile-menu";
import { config } from "../config";

export function AccountsProtectedRoute() {
  const navigate = useNavigate();
  const { isFetched, data: currentUser } = useMeQuery();

  useEffect(() => {
    if (config.FF_ENABLE_ACCEPT_PRIVACY && !currentUser?.acceptedPrivacy) {
      navigate("/accept-privacy");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, currentUser]);

  if (!isFetched) return null;

  return (
    <div>
      <TopNavigation variant="primary">
        <TopNavigation.Logo menuItems={[]} />
        <TopNavigation.Actions>
          <ProfileMenu name={currentUser?.fullName || ""} id="Member" />
        </TopNavigation.Actions>
      </TopNavigation>{" "}
      <Outlet />
    </div>
  );
}
